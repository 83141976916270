<template>
  <v-scale-transition>
    <kits-panel :kits-items="snmKits">
      <h3 class="headline grey--text text-xs-center hidden-sm-and-down">
        Snm
      </h3>
    </kits-panel>
  </v-scale-transition>
</template>

<script>
export default {
  components: {
    KitsPanel: () => import(/* webpackChunkName: "kitsPanel" */ '@/components/KitsPanel'),
  },
  data () {
    return {
      snmKits: [
        {
          icon: 'mdi-account-outline',
          path: '/snm/change_rank',
          title: 'Change Rank',
          desc: 'Change rank for dealership',
        },
      ],
    }
  },
}
</script>
